(function($) {
  var $input = $('input[name="passengersCount"]');

  $('.passengersCountMinus').on( 'click', function() {
    var newVal = parseInt($input.val()) - 1;
    if(newVal <= 99 && newVal >= 1) {
      $input.val(newVal)
    }
  });

  $('.passengersCountPlus').on( 'click', function() {
    var newVal = parseInt($input.val()) + 1;
    if(newVal <= 99 && newVal >= 1) {
      $input.val(newVal)
    }
  });
})(jQuery);
