var datagrids = {};

(function(datagrids) {

  datagrids.init = function() {
    datagrids.setDatePickerLocale();
    $.nette.ext('datagridsPlugins', {
      complete: function() {
        $('.datepicker').datepicker(
          {
            language: window.locale
          }
        );
        datagrids.setDatePickerLocale();
      }
    });

    $.nette.init();
  };

  datagrids.setDatePickerLocale = function() {
    $('[data-provide="datepicker"]').each(function() {
      $(this).attr('data-date-language', window.locale);
    });
  };

})(datagrids);